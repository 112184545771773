import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Introduction`}</h3>
    <p>{`In the fall of 2011, I was a student of public health at Kansas State University. I was sitting in a lecture for my class titled “The Built Environment” when my professor showed a Ted talk from Dan Buetner and National Geographic.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` The talk was titled, “How to live to be 100+.” In this short discussion, Mr. Buetner explained the concept of Blue Zones. As reported, Blue Zones are small communities throughout the world where the number of people who live to be 90 and 100 is significantly higher than anywhere else in the world. In addition, these areas experience a fraction of the chronic disease present elsewhere. Essentially, these zones are little havens of health spread throughout the world.`}</p>
    <p>{`In the video, Mr. Buetner explained that a variety of environmental factors shape this reality. Essentially, these factors fit into four categories: natural movement, healthy diets, right outlook, and community connectedness. As I watched the film, wheels turned in my head. I realized, for the first time, that health might be more related to our environments than the quality of our healthcare. To an aspiring physician, this was both fascinating and devastating. `}</p>
    <h3>{`The Social Determinants of Health`}</h3>
    <p>{`In the years since, I have continued to pursue an interest in the environmental factors that impact health. Nowadays, I call them the social determinants of health (SDoH). The World Health Organization eloquently defines the SDoH as, “The conditions in which people are born, grow, live, work and age. These circumstances are shaped by the distribution of money, power and resources at global, national and local levels. They are mostly responsible for health inequities - the unfair and avoidable differences in health status seen within and between countries.”`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <p>{`Research has given language to this idea. One study estimates that medical care was responsible for only 10%–15% of preventable mortality in the U.S.,`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{` while another study suggests that that the SDoH account for between 30-55% of health outcomes in the US.`}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{` Though controversial, Thomas McKeown’s book, “The Role of Medicine” discusses the idea that overall life expectancy has been more greatly impacted by improvement in living standards than by modern medical advancement.`}<sup parentName="p" {...{
        "id": "fnref-6"
      }}><a parentName="sup" {...{
          "href": "#fn-6",
          "className": "footnote-ref"
        }}>{`6`}</a></sup>{` Arguments aside, plenty of evidence suggests that health improves as social position rises.`}<sup parentName="p" {...{
        "id": "fnref-7"
      }}><a parentName="sup" {...{
          "href": "#fn-7",
          "className": "footnote-ref"
        }}>{`7`}</a></sup></p>
    <h3>{`An Example`}</h3>
    <p>{`The United States is a prime example of this mismatch. Healthcare spending reaching comprises nearly 17% of the GDP; yet, health outcomes consistently rank among the worst of the developed nations.`}<sup parentName="p" {...{
        "id": "fnref-8"
      }}><a parentName="sup" {...{
          "href": "#fn-8",
          "className": "footnote-ref"
        }}>{`8`}</a></sup>{`  In her book, “The American Health Care Paradox,” Elizabeth Bradley illustrates that not only does the U.S. spend more money on healthcare, but it also spends significantly less money on social services.`}<sup parentName="p" {...{
        "id": "fnref-9"
      }}><a parentName="sup" {...{
          "href": "#fn-9",
          "className": "footnote-ref"
        }}>{`9`}</a></sup>{` This is a tragic and costly imbalance. One need not look far to see the consequences of such discrepancies. Life expectancy within a single city can differ by up to 30 years depending on the neighborhood in which one lives.`}<sup parentName="p" {...{
        "id": "fnref-10"
      }}><a parentName="sup" {...{
          "href": "#fn-10",
          "className": "footnote-ref"
        }}>{`10`}</a></sup>{` The reality that zip code plays such a role in health and life expectancy is truly tragic.`}</p>
    <h3>{`An Economics Issue`}</h3>
    <p>{`I want to remind you that this is most certainly an economics issue. For one, healthcare is a billion- or even trillion-dollar industry, and it can occupy a significant portion of a nation’s GDP. Secondly, as we have already discussed in great detail, poverty and inequity are complicated issues. As we examine the cycle of poverty, we must remind ourselves that community health is a large player in this equation.`}<sup parentName="p" {...{
        "id": "fnref-11"
      }}><a parentName="sup" {...{
          "href": "#fn-11",
          "className": "footnote-ref"
        }}>{`11`}</a></sup>{` Finally, we must remember that poor health is expensive. A series of papers estimate the annual cost per employee for common lifestyle risks: smoking—$5,800, diabetes—$4,413, obesity—$4,237, and high blood pressure—$1,077.`}<sup parentName="p" {...{
        "id": "fnref-12"
      }}><a parentName="sup" {...{
          "href": "#fn-12",
          "className": "footnote-ref"
        }}>{`12`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-13"
      }}><a parentName="sup" {...{
          "href": "#fn-13",
          "className": "footnote-ref"
        }}>{`13`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-14"
      }}><a parentName="sup" {...{
          "href": "#fn-14",
          "className": "footnote-ref"
        }}>{`14`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-15"
      }}><a parentName="sup" {...{
          "href": "#fn-15",
          "className": "footnote-ref"
        }}>{`15`}</a></sup>{` As globalization continues to prevail and inequities persist, we must begin to realize the economics of healthcare issues. `}</p>
    <h3>{`The Rural Connection`}</h3>
    <p>{`My original prompt for this discussion was, “The Impact of Globalization on Rural Communities.” I spent a great deal of time considering this line, but ultimately, it was my experience in some of the rural villages that established the connection. In the past, rural communities contained many of the essential elements for a healthy community. `}</p>
    <p>{`Regular physical activity was often built in to the lifestyle.`}<sup parentName="p" {...{
        "id": "fnref-16"
      }}><a parentName="sup" {...{
          "href": "#fn-16",
          "className": "footnote-ref"
        }}>{`16`}</a></sup>{` These communities were often dependent on farming and ranching; travel in and between villages largely depended on walking, and the non-existence of modern appliances demanded increased efforts for common household chores. The diet was often healthier; many of these communities were likely subsistent. There was no packaged or fast food. This also encouraged a seasonal and plant-based diet. Moreover, the absence of electricity practically ensured healthy sleep schedules. People went to bed when it became dark and rose with the sun.`}<sup parentName="p" {...{
        "id": "fnref-17"
      }}><a parentName="sup" {...{
          "href": "#fn-17",
          "className": "footnote-ref"
        }}>{`17`}</a></sup>{` These rural communities also experienced a greater level of connectedness. The lifestyle encouraged permanence in a community, reliance on one another, and generosity. Even today, studies suggest that rural communities tend to be more generous and social than urban communities.`}<sup parentName="p" {...{
        "id": "fnref-18"
      }}><a parentName="sup" {...{
          "href": "#fn-18",
          "className": "footnote-ref"
        }}>{`18`}</a></sup>{` Spirituality also played a role. Evidence suggests that people who attend regular spiritual gatherings actually experience decreased levels of mortality in longitudinal studies.`}<sup parentName="p" {...{
        "id": "fnref-19"
      }}><a parentName="sup" {...{
          "href": "#fn-19",
          "className": "footnote-ref"
        }}>{`19`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-20"
      }}><a parentName="sup" {...{
          "href": "#fn-20",
          "className": "footnote-ref"
        }}>{`20`}</a></sup>{` For one reason or another, rural communities are often more spiritual than urban communities.`}<sup parentName="p" {...{
        "id": "fnref-21"
      }}><a parentName="sup" {...{
          "href": "#fn-21",
          "className": "footnote-ref"
        }}>{`21`}</a></sup>{` For these reasons and more, the traditional rural lifestyle actually serves as a great model for community health. `}</p>
    <p>{`Admittedly, in an effort to highlight the positives, I have overlooked the problems of drought, disease, and violence that were undoubtedly also issues in these communities. I simply wanted to highlight the lifestyle pattern that presented so many positive features. `}</p>
    <h3>{`The Impact of Globalization`}</h3>
    <p>{`By now, the concept of globalization has been adequately introduced. While I have almost nothing to add to this discussion, I would like to suggest that globalization is at least, in part, responsible for our transition from healthy communities to the current situation. I will list a few examples. The first of these issues is job insecurity: local farmers competing with land-rich nations, local craftsmen competing with production lines, and local labor competing with population-rich nations. A resulting issue of this insecurity has been migration. Initially, this seemed like a positive outcome, but many are beginning to recognize the detriments of losing family structure and cultural identity. In addition, the increasing “Westernization”of society threatens traditional, cultural, and spiritual values. The industrial revolution has marked a major shift from active work to sedentary lifestyles, and the increasing availability of fast and packaged foods continues to diminish homemade, plant-based diets. While globalization has resulted in many positive outcomes, its impact on the health of our communities is certainly worth noting. `}</p>
    <h3>{`The Role of Primary Care`}</h3>
    <p>{`The current circumstances beg the question, “Where do we go from here?” I believe that one solution is the integration of these social issues into the healthcare sector. Tragically, the healthcare sector is more medicine than it is health. Social issues must become the concerns of the healthcare field. At my home institution, Kansas University Medical Center, the department of family medicine is beginning to screen for these social issues. When patients come to the clinic they are given the opportunity to answer questions like the following: are you worried that you may not have stable housing, did you skip medication to save money, did you ever eat less because there wasn’t enough money for food, and do you ever feel that you lack companionship? To date, nearly 65,000 unique patients have been screened for these issues; the leading concerns in this population seem to be healthcare costs, access to food, medicine affordability, and social isolation. `}</p>
    <h3>{`Social Isolation`}</h3>
    <p>{`While screening was a good first step, the next logical action is to identify effective interventions for social issues. More importantly, what help could be offered from a primary care clinic? At the University of Kansas, Dr. Jennifer Woodward, Megan Murray, and I partnered with patients to explore creative solutions. The team decided to investigate the problem of social isolation. Studies suggest that nearly 7.7 million older adults are socially isolated in the U.S.;`}<sup parentName="p" {...{
        "id": "fnref-22"
      }}><a parentName="sup" {...{
          "href": "#fn-22",
          "className": "footnote-ref"
        }}>{`22`}</a></sup>{` other research states that nearly half of all older adults are isolated in India.`}<sup parentName="p" {...{
        "id": "fnref-23"
      }}><a parentName="sup" {...{
          "href": "#fn-23",
          "className": "footnote-ref"
        }}>{`23`}</a></sup>{` Even more concerning is the current evidence that indicates lack of relationships is a stronger risk factor for mortality than that of obesity.`}<sup parentName="p" {...{
        "id": "fnref-24"
      }}><a parentName="sup" {...{
          "href": "#fn-24",
          "className": "footnote-ref"
        }}>{`24`}</a></sup>{` This quality improvement project focused on assessing the quality of the screening question and exploring avenues for intervention. To date, the team has interviewed nearly 80 patients who reported a lack of companionship. Several different options for intervention were presented to the patients; the preliminary results suggest that this population has a great interest in aid services and volunteer opportunities. In the future, similar studies need to be performed for the other major issues followed by the implementation of these interventions (Poster presented at the Kansas Academy of Family Physicians, 2019). `}</p>
    <h3>{`The Nehru-Fulbright Study`}</h3>
    <p>{`This work has been formative in my young career, and it inspired my current work in India. The main crux of my study is this, “How are SDoH issues addressed at the primary care level in India?” I was interested to see how healthcare workers think about these issues in practice, how capacity and workflow encourages or discourages this emphasis, and how financing influences this aim. My main methods of data collection are general observation and informal interviews.`}<sup parentName="p" {...{
        "id": "fnref-25"
      }}><a parentName="sup" {...{
          "href": "#fn-25",
          "className": "footnote-ref"
        }}>{`25`}</a></sup>{` To date, I have visited eleven clinics; I have visited three public PHC’s and eight private clinics. In order to summarize the results, I have illustrated a few case studies below. `}</p>
    <h3>{`Case Studies`}</h3>
    <p>{`I wanted to title the first case, “The Minimalist.” This clinic is owned and operated by one individual. He has been practicing for over forty years, and to an outsider, his model was truly astounding. This is a small, one-room clinic. The doctor sees about 4-5 patients per hour, and he charges Rs. 50 per visit. This is an incredible price for the city of Bangalore. In fact, it is the lowest private price I have observed thus far. This physician frequently discusses the SDoH. I have seen him address home stress, education levels, and diet patterns. Frankly, I was really encouraged by this practice. My main takeaways from this clinic were the affordability of cutting down on administration and the power of individual good will. To date, the cheapest private clinics I have observed have unanimously been these one-man models. While there are sacrifices in efficiency and community contact, these models certainly remove much of the price barrier to primary care for low-income individuals. In addition to this, I recognize that these physicians have made personal lifestyle sacrifices to provide this kind of care. For one, they have sacrificed salary. Secondly, they have sacrificed time as they have assumed the primary role of caring for their patient population. `}</p>
    <p>{`The second model is called “The partnership.” This is an urban government PHC. However, it has been contracted out to be run by a private company. In addition to the standard government funding, this clinic also receives funds from private entities to bolster its services. This clinic staffs 18 individuals, one of whom is a full-time doctor. The doctor sees 70-80 patients per day. I found this fact bewildering. The services here are free. Surprisingly, this building is beautiful and full of amenities. This is largely a result of private funding. `}</p>
    <p>{`My main takeaways from this clinic are threefold. First, I have started to see that the PHC model is an excellent one. Unfortunately, I have also heard of issues with staffing, medication supply, and bribery within these structures. Secondly, I am learning about the importance of personal accountability in regards to the SDoH. One key feature of the PHC is the community health workers. In fact, the only way the doctor could possibly see so many patients in a day is the assurance that ASHA workers will follow-up with patients. This personal contact with a community is amazing, and I believe it is necessary for a primary care clinic to truly invest in social well-being. Finally, I have seen the importance of demographic responsibility. Contrary to all private clinics, a PHC is assigned a given population, called a ward. This is the population for whom the doctor is responsible. Thus, the doctor has a clear target for community health. Because the patients are sorted geographically, the doctor can address structural issues that will cover her entire patient population.`}</p>
    <p>{`The third model is called, “The Start-Up.” Broadly, these are the private clinics that consist of a robust staff of nurses and administration. In this specific case, the physician was just launching her practice. Her staff is eight total. She sees three to four patients per hour, and the consultation fee is Rs. 400. My main takeaway from this model is the business of medicine. I do not believe that this physician has ill-intentions. However, the simple financial demands of operating a clinic make tackling the SDoH very difficult. For one, these clinics often charge the costliest consultation fees. This must be, at least in part, in order to pay the extra staff to run the clinic. These physicians also take on a fair amount of risk to start a practice like this. Moreover, the problem of a scattered patient population remains. In the end, I cannot imagine that the existence of another primary care clinic is ever bad, but I do worry that such a model may never truly be able to address the roots of health issues. `}</p>
    <p>{`My last study case is titled, “Quality First.” Like the previous model, this is also a private, urban clinic. Two doctors practice in this space, and they have employed a nurse administrator, a lab tech, and a guard for the front. The physicians see eight to ten patients per hour; the consultation fee is Rs. 200 per consultation. Initially, I thought this price seemed on the lower side. During the interview, the physician highlighted that this is actually the most expensive clinic in the area. His explanation is that quality care saves money in the end. Even though his patients are largely low- to middle-income, they will spend the money to see him because they know that it will save them money in the long run. I found this to be a very interesting point. Until now, I had focused mostly on the importance of reaching an entire community, but I had not accounted for the increased cost burden of poor care. Even the best physicians cannot offer quality care and address health promotion if bound by enormous time constraints.`}</p>
    <h3>{`Best Practices`}</h3>
    <p>{`I have also been priveleged to visit a few set-ups that I will title best practices in the field. I have visited two similar models, ASHWINI hospital and the Tribal Health Initiative (THIT). To begin, these are both clinics that have taken responsibility for a specific, underserved, geographic community. Without a doubt, the medical care offered at these facilitiesis to be praised. The THI runs a massive outpatient clinic, seeing nearly 600 patients a week. They also run a 30-bed hospital and provide other services (like dental and diagnostics) as well. Still, what is more impressive is the investment in community health. Many of the staff at ASWHINI hospital are actually from the commmunity (75%). Both hospitals have established programs to train community health workers and nurses. Not only does this improve the care of the community, but it also increases the economic stability of the region at large. Moreover, THI has established two other community health initiatives: SOFA and PORGAI. SOFA is an organic farming association. They provide resources and training to local farmers in order to increase the quality of food they produce, and, in turn, increase their income. PORGAI is a women’s craft initiative. Women from the region work to create clothing and other products from organic materials grown in the area. This initiative accomplishes several aims: it preserves community practices of cratsmanship, it provides financial stability, and it prevents migration. In addition to these efforts, these organizations are working on education and political engagement as well. This practice truly is the epitome of community health, and we can only hope and pray for more driven individuals to take up such aims.`}</p>
    <h3>{`Concluding Thoughts`}</h3>
    <p>{`In closing, I want to leave you with four points. First, never underestimate the power of an individual. The best models I have observed for really investing in community health is an individual who has sacrificed prestige, money, or comfort to truly take responsibility for the health of a community. Second, if we cannot create motivation, we must, at least, create appeal. While the structure exists for PHC’s to really invest in community health, I have heard that many of the buildings stand empty. One option includes investing in higher salaries for PHC doctors, investing in the education in the area, or providing adequate support staff. Third, I am becoming ever aware of the impossible balance between quality and quantity. Unfortunately, it will always be difficult to provide quality care to mass populations. One clinic may provide great care to a small few and another may see as many as possible. As we pursue community health, it is important to understand the difficulty of this balance. Finally, I would like to state that there is a difference between health and medicine. Medical care is necessary, but I feel like we have over-emphasized it as of late. We must remember that healthcare must, first and foremost, be about health. I cannot claim that the healthcare field holds all the answers to a just and equitable society; however, I do think that healthcare has a major role to play in accomplishing true community health. `}</p>
    <h3>{`Acknowledgements`}</h3>
    <p>{`The author would like to thank several groups of people for their unique contributions to the work. Dr. Kevin Sykes served as a stateside mentor to the author, and he contributed to the design and proposal of this project. Dr. Jennifer Woodward and Megan Murray were responsible for much of the work discussed at the University of Kansas. Their inviting the author to participate in the study on social isolation proved to be a formative first step for the current Fulbright project. Dr. RK Prasad serves as the director of research at the Academy of Family Physicians in India. He has worked to facilitate connections between the author and primary care clinics throughout Bangalore. In addition, he has been a trustworthy guide in the data collection and interpretation phases of the project. In addition, Dr. Swathi S B has served as a sounding board and confidante in this work. Her insight into the Indian healthcare system has greatly aided the author in identifying sites and interpreting results. Finally, Dr. Upendra Bhojani and Dr. Dorothy Lall have been generous and wise mentors from the Institute of Public Health in Bangalore. Without their initial interest, this work would not have been possible. Their unique insight and professional expertise helped to shape the project, and they continue to support the work on a regular basis.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Blue Zones—Live Longer, Better. Blue Zones. `}<a parentName="li" {...{
            "href": "https://www.bluezones.com/"
          }}>{`https://www.bluezones.com/`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Power 9®. Blue Zones. November 2016. `}<a parentName="li" {...{
            "href": "https://www.bluezones.com/2016/11/power-9/"
          }}>{`https://www.bluezones.com/2016/11/power-9/`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`WHO | About social determinants of health. WHO. `}<a parentName="li" {...{
            "href": "http://www.who.int/social_determinants/sdh_definition/en/"
          }}>{`http://www.who.int/social_determinants/sdh_definition/en/`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`McGinnis JM, Williams-Russo P, Knickman JR. The Case For More Active Policy Attention To Health Promotion. Health Aff (Millwood). 2002;21(2):78-93. doi:10.1377/hlthaff.21.2.78`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>{`Booske BC, Athens JK, Kindig DA, Park H, Remington PL. COUNTY HEALTH RANKINGS WORKING PAPER. :22.`}<a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>{`McKeown T. The Role of Medicine: Dream, Mirage, or Nemesis? Princeton University Press; 1979.`}<a parentName="li" {...{
            "href": "#fnref-6",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-7"
        }}>{`Braveman P, Gottlieb L. The Social Determinants of Health: It’s Time to Consider the Causes of the Causes. Public Health Rep. 2014;129(Suppl 2):19-31.`}<a parentName="li" {...{
            "href": "#fnref-7",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-8"
        }}>{`How Does the U.S. Healthcare System Compare to Other Countries? `}<a parentName="li" {...{
            "href": "https://www.pgpf.org/blog/2019/07/how-does-the-us-healthcare-system-compare-to-other-countries"
          }}>{`https://www.pgpf.org/blog/2019/07/how-does-the-us-healthcare-system-compare-to-other-countries`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-8",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-9"
        }}>{`Bradley E, Taylor L. The American Health Care Paradox: Why Spending More Is Getting Us Less. Public Affairs; 2013.`}<a parentName="li" {...{
            "href": "#fnref-9",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-10"
        }}>{`How Your Zip Code Could Affect Your Lifespan | Time. `}<a parentName="li" {...{
            "href": "https://time.com/5608268/zip-code-health/"
          }}>{`https://time.com/5608268/zip-code-health/`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-10",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-11"
        }}>{`Fair Progress? : Economic Mobility Across Generations Around the World. `}<a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/28428"
          }}>{`https://openknowledge.worldbank.org/handle/10986/28428`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-11",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-12"
        }}>{`Berman M, Crane R, Seiber E, Munur M. Estimating the cost of a smoking employee. Tob Control. 2014;23(5):428-433. doi:10.1136/tobaccocontrol-2012-050888`}<a parentName="li" {...{
            "href": "#fnref-12",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-13"
        }}>{`Obesity and Workers’ Comp Costs. `}<a parentName="li" {...{
            "href": "https://www.insurancejournal.com/magazines/mag-features/2013/01/14/276698.htm"
          }}>{`https://www.insurancejournal.com/magazines/mag-features/2013/01/14/276698.htm`}</a>{`. Accessed January 23, 2020.`}<a parentName="li" {...{
            "href": "#fnref-13",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-14"
        }}>{`Van Nuys K, Globe D, Ng-Mak D, Cheung H, Sullivan J, Goldman D. The association between employee obesity and employer costs: evidence from a panel of U.S. employers. Am J Health Promot AJHP. 2014;28(5):277-285. doi:10.4278/ajhp.120905-QUAN-428`}<a parentName="li" {...{
            "href": "#fnref-14",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-15"
        }}>{`Kowlessar NM, Goetzel RZ, Carls GS, Tabrizi MJ, Guindon A. The Relationship Between 11 Health Risks and Medical and Productivity Costs for a Large Employer. J Occup Environ Med. 2011;53(5):468–477. doi:10.1097/JOM.0b013e31821586b8`}<a parentName="li" {...{
            "href": "#fnref-15",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-16"
        }}>{`Ravussin E, Valencia ME, Esparza J, Bennett PH, Schulz LO. Effects of a Traditional Lifestyle on Obesity in Pima Indians. Diabetes Care. 1994;17(9):1067-1074. doi:10.2337/diacare.17.9.1067`}<a parentName="li" {...{
            "href": "#fnref-16",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-17"
        }}>{`Natural sleep cycles identified in rural community. ScienceDaily. `}<a parentName="li" {...{
            "href": "https://www.sciencedaily.com/releases/2015/03/150318074513.htm"
          }}>{`https://www.sciencedaily.com/releases/2015/03/150318074513.htm`}</a>{`. Accessed January 24, 2020.`}<a parentName="li" {...{
            "href": "#fnref-17",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-18"
        }}>{`Ma Q, Pei G, Jin J. What Makes You Generous? The Influence of Rural and Urban Rearing on Social Discounting in China. PLOS ONE. 2015;10(7):e0133078. doi:10.1371/journal.pone.0133078`}<a parentName="li" {...{
            "href": "#fnref-18",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-19"
        }}>{`Idler E, Blevins J, Kiser M, Hogue C. Religion, a social determinant of mortality? A 10-year follow-up of the Health and Retirement Study. PLOS ONE. 2017;12(12):e0189134. doi:10.1371/journal.pone.0189134`}<a parentName="li" {...{
            "href": "#fnref-19",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-20"
        }}>{`You Asked: Do Religious People Live Longer? Time. `}<a parentName="li" {...{
            "href": "https://time.com/5159848/do-religious-people-live-longer/"
          }}>{`https://time.com/5159848/do-religious-people-live-longer/`}</a>{`. Accessed January 24, 2020.`}<a parentName="li" {...{
            "href": "#fnref-20",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-21"
        }}>{`Nikkhah H, Nia M, Sadeghi S, Fani M. The Mean Difference of Religiosity between Residents of Rural Areas and Urban Areas of Mahmoudabad City. Asian Soc Sci. 2015;11. doi:10.5539/ass.v11n2p144`}<a parentName="li" {...{
            "href": "#fnref-21",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-22"
        }}>{`Epidemiology of Social Isolation: National Health and Aging Trends Study | The Journals of Gerontology: Series B | Oxford Academic. `}<a parentName="li" {...{
            "href": "https://academic.oup.com/psychsocgerontology/article/75/1/107/4953727"
          }}>{`https://academic.oup.com/psychsocgerontology/article/75/1/107/4953727`}</a>{`. Accessed January 24, 2020.`}<a parentName="li" {...{
            "href": "#fnref-22",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-23"
        }}>{`Grover. Loneliness: Does it need attention! `}<a parentName="li" {...{
            "href": "http://www.jgmh.org/article.asp?issn=2348-9995;year=2019;volume=6;issue=1;spage=1;epage=3;aulast=Grover"
          }}>{`http://www.jgmh.org/article.asp?issn=2348-9995;year=2019;volume=6;issue=1;spage=1;epage=3;aulast=Grover`}</a>{`. Accessed January 24, 2020.`}<a parentName="li" {...{
            "href": "#fnref-23",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-24"
        }}>{`Social isolation, loneliness in older people pose health risks. `}<a parentName="li" {...{
            "href": "https://www.nia.nih.gov/news/social-isolation-loneliness-older-people-pose-health-risks"
          }}>{`https://www.nia.nih.gov/news/social-isolation-loneliness-older-people-pose-health-risks`}</a>{`. Accessed January 24, 2020.`}<a parentName="li" {...{
            "href": "#fnref-24",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-25"
        }}>{`RWJF - Qualitative Research Guidelines Project | Informal Interviews | Informal Interviewing. `}<a parentName="li" {...{
            "href": "http://www.qualres.org/HomeInfo-3631.html"
          }}>{`http://www.qualres.org/HomeInfo-3631.html`}</a>{`. Accessed January 22, 2020.`}<a parentName="li" {...{
            "href": "#fnref-25",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      